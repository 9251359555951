import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getFormattedDate,
  getImageFromList,
  getOptimizedImages,
} from "../../../../utilities/utili";
import "../styles.scss";

const SingleBlog = ({ blog_json, isMobile }) => {
  const navigate = useNavigate();
  const { blog_slug } = useParams();
  const [singleBlog, setSingleBlog] = useState({});

  useEffect(() => {
    const blog = blog_json
      .filter((blogs) => blogs.page_type === "post")
      .filter((blog) => blog.slug === blog_slug);
    if (blog.length) {
      setSingleBlog(blog[0]);
      document.title = blog[0].title; // Set the page title
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.content = blog[0].excerpt; // Set the meta description
      }
    } else {
      navigate("/blog");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Object.keys(singleBlog).length ? (
    <div className="page-content blog">
      <section className="single-blog-section my-20 pt-6">
        <div className="container mx-auto py-8 px-6">
          <div className="md:mx-20 md:pr-20">
            <div className="py-3">
              <h2 className="text-[2rem] font-bold mb-3 relative border-b py-2">
                {singleBlog.title}
              </h2>
              <p>{getFormattedDate(singleBlog.date)}</p>
            </div>
            <div>
              {getOptimizedImages(isMobile, singleBlog.media).length && (
                <img
                  src={`https://${
                    getOptimizedImages(isMobile, singleBlog.media)[0].link
                  }`}
                  className="img-fluid w-full"
                  alt=""
                />
              )}
              <p className="mt-3">
                <div dangerouslySetInnerHTML={{ __html: singleBlog.content }} />
              </p>
            </div>
            {/* <div className="definitions mt-6">
              <div className="social-links border-t border-b py-6">
                <span className="mr-4">Share:</span>
                <Link to="/" className="text-2xl mr-3">
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
                <Link to="/" className="text-2xl mr-3">
                  <i className="fa-brands fa-twitter"></i>
                </Link>
                <Link to="/" className="text-2xl mr-3">
                  <i className="fa-brands fa-pinterest-square"></i>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  ) : null;
};

export default SingleBlog;
