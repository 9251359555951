import "./styles.scss";

const About = () => {
  return (
    <div className="page-content about">
      <div className="relative">
        <div
          className="flex-container justify-center bg-cover bg-no-repeat bg-fixed banner-bg"
          style={{
            backgroundImage: "url(//museumofindianart.com/260486622193354730797456921867802247/tmpzsgob62z-1500x1000.jpg)",
          }}
        >
          <div className="text-center z-10">
            <h1 className="text-white">
              About <span className="primary-clr">Us</span>
            </h1>
            <h2 className="text-white">{window.perspect.site_description}</h2>
          </div>
        </div>
      </div>
      <section>
        <div className="container mx-auto py-8">
          <div className="text-center py-3">
            <h3 className="text-3xl font-bold mb-8 relative main-h">
              Artwork
            </h3>
          </div>
          <div className="grid lg:grid-cols-2 mg:grid-cols-2 sm:grid-cols-1 box-gap">
            <div>
              <img
                src="//museumofindianart.com/297339633770512323296818196904282806/13-priest-0-marked-750x750.png"
                className="img-fluid w-100"
                alt=""
              />
            </div>
            <div>
            <p className="mb-3">
                Indian art is known for its use of asymmetry and non-realistic representation. Indian artists often use bold colors, flowing lines, and exaggerated forms to convey emotion and spiritual energy. European and American art, meanwhile, tends to be more realistic and focused on accurate representation.
              </p>
              <p className="mb-3">
                Such asymmetry in Indian art has the power to transform spaces and lives. By making it easy for people to discover and purchase unique and beautiful pieces of Indian art, we aim to help them create spaces that inspire creativity, making it possible for everyone to experience the beauty and joy of an incongruent world through art in their daily lives.
              </p>
              <p className="mb-3">
                Every piece of artwork sold by the Museum of Indian Art is unique.  Sold artwork will be removed from sale shortly after being purchased.
              </p>
              <p className="mb-3">  
                There will only be one printed copy of a single piece of artwork.
              </p>
              <p className="mb-3">  
                Delivery can take between 1-2 weeks.
              </p>
              {/* <p className="mb-3">
                You can write about your site here.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      {/* About view Modal */}
      <section className="bg-primary-clr py-10">
        <div className="container mx-auto text-center text-white pb-6">
          <div
            id="about-slider"
            className="carousel slide relative"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner relative w-full overflow-hidden md:h-80 mb-6">
              <div className="carousel-item active relative float-left w-full">
                <div className="mb-5 md:mx-20">
                  <img
                    src="//museumofindianart.com/206071944203586808217094711287994163/33-shalwar-kameez-0-marked-100x100.png"
                    className="img-fluid w-20 h-20 mx-auto rounded-full mb-4"
                    alt=""
                  />
                  <span className="font-semibold text-lg">Mission</span>
                  <p>
                  The Museum of Indian Art's mission is to help make your living space thought-provoking and beautiful. From traditional to modern, each piece in our collection is handpicked with a discerning eye for its ability to inspire. We strive to make art more accessible, and we're dedicated to showcasing the diverse and vibrant cultural heritage of India through each piece we sell. Our goal is to help you find the perfect piece of art to brighten your home, office, or gift to your loved ones. We believe that art should be enjoyable and fun, so come and explore our collection, it's guaranteed to put a smile on your face.
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="mb-5 md:mx-20">
                  <img
                    src="//museumofindianart.com/134571089538602171121756740376331543/34-ornate-sitar-1-marked-100x100.png"
                    className="img-fluid w-20 h-20 mx-auto rounded-full mb-4"
                    alt=""
                  />
                  <span className="font-semibold text-lg">
                    Thought Provoking Art
                  </span>
                  <p className="mb-3">
                  Thought-provoking Indian art has immense value in today's world as it allows for reflection and dialogue on the important issues faced by society. Indian artists have always been at the forefront of exploring and addressing social, political, and cultural issues in their work. The art created by them can be powerful tool to create awareness and evoke emotions among viewers on issues such as poverty, inequality, and discrimination. Additionally, it often serves as a means of providing alternative perspectives on history, culture, and society. By challenging the status quo, thought-provoking Indian art can inspire change and progress. Furthermore, Indian contemporary art can offer perspectives and critique on the effects of globalization, immigration, and the fusion of different cultures. It can also be an important means of promoting diversity and inclusivity. Through the creation and consumption of thought-provoking Indian art, individuals can gain a deeper understanding of the world and their place within it, making it valuable not only from an aesthetic perspective, but also from a social and cultural one.
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="mb-5 md:mx-20">
                  <img
                    src="//museumofindianart.com/87179631752659553040289624640848813/13-close-up-portrait-3-marked-100x100.png"
                    className="img-fluid w-20 h-20 mx-auto rounded-full mb-4"
                    alt=""
                  />
                  <span className="font-semibold text-lg">
                    Indian Culture
                  </span>
                  <p>
                    What is Indian culture?  Our culture consists of art, language, music, food and traditions from our forebearers.
                    Art is a powerful medium for preserving and promoting a culture's traditions, values, and history. In the case of Indian culture, art has played a vital role in keeping the rich heritage alive for thousands of years. From ancient cave paintings to modern contemporary art, Indian art has always been a reflection of the society it emerged from. From elaborate temple carvings to delicate miniature paintings, Indian art has always been a way to showcase the country's customs, myths, and religions. Not only it holds historical and cultural significance but also it can be a way to educate future generations about their cultural heritage and identity. Additionally, it is also a medium to showcase the country's diversity and multiculturalism. Through the preservation and promotion of Indian art, future generations can gain a deeper understanding and appreciation of the culture, and it could be an effective way to keep Indian culture alive for centuries to come.
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-indicators flex justify-center p-0 mb-4">
              <button
                type="button"
                data-bs-target="#about-slider"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#about-slider"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#about-slider"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
